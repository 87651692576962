import React from 'react';

import Layout from '../../components/Layout';
import Feature from '../../components/Feature';
import theme from '../../theme';
import AcademyIcon from '../../images/academie.jpg';
import B1 from '../../images/new/aca_fct1.jpg';
import B2 from '../../images/new/aca_fct2.jpg';
import B3 from '../../images/new/aca_fct3.jpg';

const AcademyPage = () => (
  <Layout>
    <Feature
      previous="/features/intra"
      next="/features/challenges"
      title="Académie"
      color={theme.palette.blue.main}
      icon={AcademyIcon}
      subtitle="Facilitez le partage d'expertise et de savoir-faire."
      description="Chaque collaborateur crée son contenu de formation et le partage à l'ensemble de l'organisation. Un excellent moyen de capitaliser le savoir de votre entreprise mais aussi et surtout de créer une culture de partage et de l'entraide indispensable à l'innovation."
      subSections={[
        'Capitaliser sur vos connaissances',
        'Favoriser l’entre-aide et le partage',
        'Former vos collaborateurs efficacement',
        'Réduction de vos coûts de formation',
      ]}
      sections={[
        {
          title: 'Création de formations',
          description:
            'Chacun de vos collaborateurs peut créer facilement des formations pour partager ses connaissances et ses savoir-faire.',
          image: B1,
        },
        {
          title: 'Digitalisation de vos formations',
          description:
            'Digitalisez, centralisez et diffusez facilement toutes vos formations pour former au quotidien vos collaborateurs.',
          image: B2,
        },
        {
          title: 'Accès à des formations externes',
          description:
            'Bénéficiez de formations externes de nos partenaires, gratuites ou payantes, pour vous former sur des sujets précis.',
          image: B3,
        },
      ]}
    />
  </Layout>
);

export default AcademyPage;
